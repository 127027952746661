import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Template6 from './Template6';
import Template7 from './Template7';
import Template8 from './Template8';
import Template9 from './Template9';

function App() {
  return (
    <>
      <Router>

        <Routes>


          {/* <Route exact path="/" Component={MainColumn} /> */}
          {/* <Route exact path="/hawal-3432" Component={Template} />
          <Route exact path="/hawal-7584" Component={Template2} />
          <Route exact path="/hawal-9812" Component={Template3} />
          <Route exact path="/hawal-6599" Component={Template4} /> */}
          {/* <Route exact path="/hawal-18523" Component={Template6} /> */}
          {/* <Route exact path="/hawal-28523" Component={Template6} /> */}
          {/* <Route exact path="/hawal-28523" Component={Template6} /> */}
          {/* <Route exact path="/hawal-4625" Component={Template5} /> */}
          {/* <Route exact path="/hawal-18623" Component={Template6} /> */}
          {/* <Route exact path="/hawal-22623" Component={Template7} /> */}
          {/* <Route exact path="/sorani-hawal-2190124" Component={Template8} /> */}
          <Route exact path="/sorani-hawal-6280724" Component={Template7} />


          {/* <Route exact path="/sorani-hawal-7190524" Component={Template6} /> */}



          {/* <Route exact path="/sorani-hawal-2160124" Component={Template9} /> */}
          {/* <Route exact path="/hawal-122623" Component={Template5} /> */}
          {/* <Route exact path="/hawal/7583" Component={Template} /> */}


        </Routes>

      </Router>
      {/* <MainColumn /> */}
    </>
  );
}

export default App;