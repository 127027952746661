import React from 'react'

function Footer() {
    return (
        <>

            <footer class="footer--landing">



                <div class="section section--foter-top smallMobileImage">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-3 col-sm-2 col-xs-12">
                                <div class="footer__img-container right">
                                    <img class="lazyimg" src="https://www.rudaw.net/images/img-footer-phones.png" width="110" alt="Rudaw Mobile app" title="Rudaw Mobile app" />
                                </div>
                            </div>
                            <div class="col-md-5 col-sm-5 col-xs-12">
                                <div class="footer__text-container">
                                    <h2 class="footer__title">
                                        هەواڵ دەخەینە گیرفانتانەوە
                                    </h2>
                                    <h4 class="footer__subtitle">
                                        ئه‌پڵیکەیشنی رووداو دابه‌زێنه‌
                                    </h4>
                                </div>
                                <div class="btn-container left">
                                    <a title="Rudaw on App Store" href="https://itunes.apple.com/us/app/rudaw/id630574728?mt=8" class="btn--store btn--apple">
                                        <img src="https://www.rudaw.net/images/ico-apple.svg" height="40" title="Rudaw on App Store" alt="Rudaw on App Store" />
                                    </a>
                                    <a title="Rudaw on Play Store" href="https://play.google.com/store/apps/details?id=com.borninteractive.rudaw" class="btn--store btn--android">
                                        <img src="https://www.rudaw.net/images/ico-android.svg" height="40" title="Rudaw on Play Store" alt="Rudaw on Play Store" />
                                    </a>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>


                <div class="section section--footer-mid">
                    <div class="container">
                        <div class="border">
                            <div class="row">



                                <div class="col-md-12 col-sm-12 col-xs-12">
                                    <div class="btn-container right">
                                        <a title="WATCH LIVE" href="https://www.rudaw.net/sorani/onair/tv/live" class="btn--full btn--ico orange">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="22.001" height="19" viewBox="0 0 22.001 19">
                                                <path id="ico-footer-tv" d="M1018.05,10206.8H999.385a1.624,1.624,0,0,1-1.666-1.6v-12.5a1.646,1.646,0,0,1,1.666-1.7h18.665a1.647,1.647,0,0,1,1.66,1.7v12.5A1.625,1.625,0,0,1,1018.05,10206.8Zm-18.665-15.1a0.972,0.972,0,0,0-1,1v12.5a1.036,1.036,0,0,0,1,1h18.665a1.037,1.037,0,0,0,1-1v-12.5a0.972,0.972,0,0,0-1-1H999.385Zm13.575,18.3h-8.49a0.375,0.375,0,0,1-.34-0.3,0.386,0.386,0,0,1,.34-0.4h8.49a0.386,0.386,0,0,1,.34.4A0.375,0.375,0,0,1,1012.96,10210Zm-4.24,0a0.375,0.375,0,0,1-.34-0.3v-3.2a0.3,0.3,0,0,1,.34-0.3,0.3,0.3,0,0,1,.33.3v3.2A0.373,0.373,0,0,1,1008.72,10210Z" transform="translate(-997.719 -10191)" />
                                            </svg>
                                            <span>WATCH LIVE</span>
                                        </a>
                                        <a title="Radio Live" href="https://www.rudaw.net/sorani/onair/radio/live" class="btn--full btn--ico orange">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="20" viewBox="0 0 11 20">
                                                <path id="ico-footer-mic" d="M1154.34,10200.7a0.309,0.309,0,0,0-.35.3v2.1a4.81,4.81,0,0,1-9.62,0v-2.1a0.3,0.3,0,0,0-.34-0.3,0.309,0.309,0,0,0-.35.3v2.1a5.55,5.55,0,0,0,5.16,5.5v1.7h-1.72a0.386,0.386,0,0,0-.34.4,0.375,0.375,0,0,0,.34.3h4.12a0.388,0.388,0,0,0,.35-0.3,0.394,0.394,0,0,0-.35-0.4h-1.71v-1.7a5.541,5.541,0,0,0,5.15-5.5v-2.1A0.3,0.3,0,0,0,1154.34,10200.7Zm-5.16,6.2a3.788,3.788,0,0,0,3.78-3.8v-8.3a3.78,3.78,0,1,0-7.56,0v8.3A3.794,3.794,0,0,0,1149.18,10206.9Zm-3.09-12.1a3.09,3.09,0,1,1,6.18,0v8.3a3.09,3.09,0,1,1-6.18,0v-8.3Z" transform="translate(-1143.69 -10191)" />
                                            </svg>
                                            <span>Radio Live</span>
                                        </a>
                                    </div>
                                </div>


                            </div>
                            <div class="copy__useful mobileLinks">
                                <a title="نهێنیپارێزی " href="https://www.rudaw.net/sorani/privacy-plicy">نهێنیپارێزی </a>
                                <a title="مەرجەکان" href="https://www.rudaw.net/sorani/terms">مەرجەکان</a>


                            </div>
                        </div>
                    </div>
                </div>



                <div class="section section--footer-bottom">
                    <div class="container">
                        <div class="border">
                            <div class="row">

                                <div class="col-md-2 col-sm-2 col-xs-12">
                                    <ul class="footer__link-list clear-style">
                                        <li class="footer__link-item"><a title="کوردستان" href="https://www.rudaw.net/sorani/kurdistan" class="footer__link">کوردستان</a></li><li class="footer__link-item"><a title="ئەو پرسیارانەی زۆر دەکرێن" href="https://www.rudaw.net/sorani/faq" class="footer__link">ئەو پرسیارانەی زۆر دەکرێن</a></li><li class="footer__link-item"><a title="ر‌ێكلام بكه‌" href="https://www.rudaw.net/sorani/advertise" class="footer__link">ر‌ێكلام بكه‌</a></li><li class="footer__link-item"><a title="پێشكێشكاران" href="https://www.rudaw.net/sorani/presenters" class="footer__link">پێشكێشكاران</a></li>                            </ul>
                                </div>
                                <div class="col-md-2 col-sm-2 col-xs-12">
                                    <ul class="footer__link-list clear-style">
                                        <li class="footer__link-item"><a title="رۆژهەڵاتی نێوەڕاست" href="https://www.rudaw.net/sorani/middleeast" class="footer__link">رۆژهەڵاتی نێوەڕاست</a></li><li class="footer__link-item"><a title="بیروڕا" href="https://www.rudaw.net/sorani/opinion" class="footer__link">بیروڕا</a></li><li class="footer__link-item"><a title="رووداوی تۆ" href="https://www.rudaw.net/sorani/login-or-register" class="footer__link">رووداوی تۆ</a></li><li class="footer__link-item"><a title="بڵاوکراوەکان" href="https://www.rudaw.net/sorani/publications" class="footer__link">بڵاوکراوەکان</a></li>                            </ul>
                                </div>
                                <div class="col-md-2 col-sm-2 col-xs-12">
                                    <ul class="footer__link-list clear-style">
                                        <li class="footer__link-item"><a title="هەڤپەیڤین" href="https://www.rudaw.net/sorani/interview" class="footer__link">هەڤپەیڤین</a></li><li class="footer__link-item"><a title="مەڵتیمیدیا" href="https://www.rudaw.net/sorani/multimedia" class="footer__link">مەڵتیمیدیا</a></li><li class="footer__link-item"><a title="هەلی کار" href="https://www.rudaw.net/sorani/careers" class="footer__link">هەلی کار</a></li><li class="footer__link-item"><a title="فریکوێنسییەکانی رووداو" href="https://www.rudaw.net/footer-men/rudaw-frequency" class="footer__link">فریکوێنسییەکانی رووداو</a></li>                            </ul>
                                </div>
                                <div class="col-md-2 col-sm-2 col-xs-12">
                                    <ul class="footer__link-list clear-style">
                                        <li class="footer__link-item"><a title="وەرزش" href="https://www.rudaw.net/sorani/sports" class="footer__link">وەرزش</a></li><li class="footer__link-item"><a title="کەشوهەوا" href="https://www.rudaw.net/sorani/weather" class="footer__link">کەشوهەوا</a></li><li class="footer__link-item"><a title="ئابووری" href="https://www.rudaw.net/sorani/business" class="footer__link">ئابووری</a></li>                            </ul>
                                </div>
                                <div class="col-md-2 col-sm-2 col-xs-12">
                                    <ul class="footer__link-list clear-style">
                                        <li class="footer__link-item"><a title="دەربارەی ئێمە" href="https://www.rudaw.net/sorani/about" class="footer__link">دەربارەی ئێمە</a></li><li class="footer__link-item"><a title="جیهان" href="https://www.rudaw.net/sorani/world" class="footer__link">جیهان</a></li><li class="footer__link-item"><a title="تەندروستی" href="https://www.rudaw.net/sorani/health" class="footer__link">تەندروستی</a></li>                            </ul>
                                </div>
                                <div class="col-md-2 col-sm-2 col-xs-12">
                                    <ul class="footer__link-list clear-style">
                                        <li class="footer__link-item"><a title="پەیوەندی کردن" href="https://www.rudaw.net/sorani/contactus" class="footer__link">پەیوەندی کردن</a></li><li class="footer__link-item"><a title="کولتوور و ستایل" href="https://www.rudaw.net/sorani/culture" class="footer__link">کولتوور و ستایل</a></li><li class="footer__link-item"><a title="پەیامنێران" href="https://www.rudaw.net/sorani/reporters" class="footer__link">پەیامنێران</a></li>                            </ul>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>





                <div class="section--foter-copy">
                    <div class="container">
                        <div class="copy">
                            <div class="">
                                <p>
                                    &#169; مافی بڵاوکردنەوەی پارێزراوە بۆ رووداو 2023
                                </p>
                            </div>
                            <div class="copy__useful desktopLinks">

                                <a title="نهێنیپارێزی " href="https://www.rudaw.net/sorani/privacy-plicy">نهێنیپارێزی </a>
                                <a title="مەرجەکان" href="https://www.rudaw.net/sorani/terms">مەرجەکان</a>


                            </div>
                        </div>
                    </div>
                </div>

                <input type="hidden" id="nodeID" value="736277" />
            </footer>

        </>
    )
}

export default Footer