import React from 'react'

function Aside() {
    return (
        <>
            <aside class="article-main__sidebar col-md-4 col-sm-12 col-xs-12">










                <div class="article__aside article__aside--missed selectionShareable">
                    <h3 class="article__aside-title">
                        <span>
                            ئه&zwnj;مانه&zwnj;ش ببینه&zwnj;
                        </span>
                    </h3>
                    <div class="article__aside-article-cont selectionShareable">


                        <div class="article__aside-article article__aside-article--highlighted selectionShareable">
                            <div class="img-container selectionShareable">
                                <a title=" ئەو باندەی ئاسایشی سلێمانی دەڵێت دەستگیرکراون، لەگەڵ بەشێک لەو کەرەستانەی بەکاریان هێناون." href="https://www.rudaw.net/sorani/kurdistan/0406202312" class="article__aside-article-link">
                                    <img src="https://www.rudaw.net/s3/rudaw.net/ContentFiles/736326Image1.jpg?mode=crop&amp;quality=70&amp;rand=1&amp;scale=both&amp;w=362&amp;h=226&amp;version=5306448" alt=" ئەو باندەی ئاسایشی سلێمانی دەڵێت دەستگیرکراون، لەگەڵ بەشێک لەو کەرەستانەی بەکاریان هێناون." title=" ئەو باندەی ئاسایشی سلێمانی دەڵێت دەستگیرکراون، لەگەڵ بەشێک لەو کەرەستانەی بەکاریان هێناون." />
                                </a>
                            </div>
                            <div class="text-container selectionShareable">
                                <h4 class="article__aside-article-title">
                                    <a title="ئاسایشی سلێمانی باندێک بە تۆمەتی جادوو و بازرگانیکردن بە مادەی هۆشبەر دەستگیردەکات" href="https://www.rudaw.net/sorani/kurdistan/0406202312">ئاسایشی سلێمانی باندێک بە تۆمەتی جادوو و بازرگانیکردن بە مادەی هۆشبەر دەستگیردەکات</a>
                                </h4>
                            </div>
                        </div>


                        <div class="article__aside-article article__aside-article selectionShareable">
                            <div class="img-container selectionShareable">
                                <a title="شنیار هونەری تەمەن 21 ساڵ کە لە مانگی شوباتی 2022 بەهۆی سووتانەوە گیانی لەدەستدا." href="https://www.rudaw.net/sorani/kurdistan/0406202311" class="article__aside-article-link">
                                    <img src="https://www.rudaw.net/s3/rudaw.net/ContentFiles/736323Image1.jpg?mode=crop&amp;quality=70&amp;rand=1&amp;scale=both&amp;w=362&amp;h=226&amp;version=5306415" alt="شنیار هونەری تەمەن 21 ساڵ کە لە مانگی شوباتی 2022 بەهۆی سووتانەوە گیانی لەدەستدا." title="شنیار هونەری تەمەن 21 ساڵ کە لە مانگی شوباتی 2022 بەهۆی سووتانەوە گیانی لەدەستدا." />
                                </a>
                            </div>
                            <div class="text-container selectionShareable">
                                <h4 class="article__aside-article-title">
                                    <a title="لە سلێمانی بکوژی ژنێک بە بەندکردنی تاهەتایی سزادرا" href="https://www.rudaw.net/sorani/kurdistan/0406202311">لە سلێمانی بکوژی ژنێک بە بەندکردنی تاهەتایی سزادرا</a>
                                </h4>
                            </div>
                        </div>
                        <div class="article__aside-article article__aside-article selectionShareable">
                            <div class="img-container selectionShareable">
                                <a title="ناوەندی هاوکاری دەڵێت ئێران چەکی قورس لەسەر سنوورەکان کۆدەکاتەوە. " href="https://www.rudaw.net/sorani/kurdistan/0406202310" class="article__aside-article-link">
                                    <img src="https://www.rudaw.net/s3/rudaw.net/ContentFiles/736315Image1.jpg?mode=crop&amp;quality=70&amp;rand=1&amp;scale=both&amp;w=362&amp;h=226&amp;version=5306335" alt="ناوەندی هاوکاری دەڵێت ئێران چەکی قورس لەسەر سنوورەکان کۆدەکاتەوە. " title="ناوەندی هاوکاری دەڵێت ئێران چەکی قورس لەسەر سنوورەکان کۆدەکاتەوە. " />
                                </a>
                            </div>
                            <div class="text-container selectionShareable">
                                <h4 class="article__aside-article-title">
                                    <a title="ناوەندی هاوکاری: ئێران دەیەوێ هێرش بکاتە سەر بنکەکانمان" href="https://www.rudaw.net/sorani/kurdistan/0406202310">ناوەندی هاوکاری: ئێران دەیەوێ هێرش بکاتە سەر بنکەکانمان</a>
                                </h4>
                            </div>
                        </div>
                        <div class="article__aside-article article__aside-article selectionShareable">
                            <div class="img-container selectionShareable">
                                <a title="کارپێکەرێکی موەلیدە لە هەولێر. وێنە: بڵند تاهیر - رووداو" href="https://www.rudaw.net/sorani/kurdistan/040620238" class="article__aside-article-link">
                                    <img src="https://www.rudaw.net/s3/rudaw.net/ContentFiles/736289Image1.jpg?mode=crop&amp;quality=70&amp;rand=1&amp;scale=both&amp;w=362&amp;h=226&amp;version=5306030" alt="کارپێکەرێکی موەلیدە لە هەولێر. وێنە: بڵند تاهیر - رووداو" title="کارپێکەرێکی موەلیدە لە هەولێر. وێنە: بڵند تاهیر - رووداو" />
                                </a>
                            </div>
                            <div class="text-container selectionShareable">
                                <h4 class="article__aside-article-title">
                                    <a title="نرخی ئەمپێری موەلیدە لە سلێمانی و هەڵەبجە دیاریکرا؛ هەزار بۆ 1500 دینارە" href="https://www.rudaw.net/sorani/kurdistan/040620238">نرخی ئەمپێری موەلیدە لە سلێمانی و هەڵەبجە دیاریکرا؛ هەزار بۆ 1500 دینارە</a>
                                </h4>
                            </div>
                        </div>
                        <div class="article__aside-article article__aside-article selectionShareable">
                            <div class="img-container selectionShareable">
                                <a title="گربوونەوەی جووتیارانی کورد و تورکمان" href="https://www.rudaw.net/sorani/kurdistan/040620237" class="article__aside-article-link">
                                    <img src="https://www.rudaw.net/s3/rudaw.net/ContentFiles/736285Image1.jpg?mode=crop&amp;quality=70&amp;rand=1&amp;scale=both&amp;w=362&amp;h=226&amp;version=5305973" alt="گربوونەوەی جووتیارانی کورد و تورکمان" title="گربوونەوەی جووتیارانی کورد و تورکمان" />
                                </a>
                            </div>
                            <div class="text-container selectionShareable">
                                <h4 class="article__aside-article-title">
                                    <a title="گردبوونەوەی جووتیارانی تۆپزاوا دژی دەستبەسەرداگرتنی زەوییەکانیان بەردەوامە؛ &quot;زەوییەکانمان شەرەفمانە&quot;" href="https://www.rudaw.net/sorani/kurdistan/040620237">گردبوونەوەی جووتیارانی تۆپزاوا دژی دەستبەسەرداگرتنی زەوییەکانیان بەردەوامە؛ "زەوییەکانمان شەرەفمانە"</a>
                                </h4>
                            </div>
                        </div>



                    </div>
                </div>







                <div class="article__aside article__aside--missed selectionShareable">
                    <h3 class="article__aside-title">
                        <span>
                            دوایین هەواڵەکان
                        </span>
                    </h3>
                    <div class="article__aside-article-cont selectionShareable">


                        <div class="article__aside-article article__aside-article--highlighted selectionShareable">
                            <div class="img-container selectionShareable">
                                <a title="دیمەنێکی شاری ورمێ" href="https://www.rudaw.net/sorani/kurdistan/040620233" class="article__aside-article-link">
                                    <img src="https://www.rudaw.net/s3/rudaw.net/ContentFiles/736241Image1.jpg?mode=crop&amp;quality=70&amp;rand=1&amp;scale=both&amp;w=362&amp;h=226&amp;version=5305944" alt="دیمەنێکی شاری ورمێ" title="دیمەنێکی شاری ورمێ" />
                                </a>
                            </div>
                            <div class="text-container selectionShareable">
                                <h4 class="article__aside-article-title">
                                    <a title="کەشناسیی ورمێ: هاوینی ئەمساڵ دوو پلە گەرمتر دەبێت" href="https://www.rudaw.net/sorani/kurdistan/040620233">کەشناسیی ورمێ: هاوینی ئەمساڵ دوو پلە گەرمتر دەبێت</a>
                                </h4>
                            </div>
                        </div>


                        <div class="article__aside-article article__aside-article selectionShareable">
                            <div class="img-container selectionShareable">
                                <a title=" رۆژی 7-10-2022 لە هەولێر، دوو بۆمب بە ئۆتۆمبێلی هاوکار جافدا تەقینەوە و بەهۆیەوە گیانی لەدەستدا " href="https://www.rudaw.net/sorani/kurdistan/040620236" class="article__aside-article-link">
                                    <img src="https://www.rudaw.net/s3/rudaw.net/ContentFiles/736277Image1.jpg?mode=crop&amp;quality=70&amp;rand=1&amp;scale=both&amp;w=362&amp;h=226&amp;version=5306002" alt=" رۆژی 7-10-2022 لە هەولێر، دوو بۆمب بە ئۆتۆمبێلی هاوکار جافدا تەقینەوە و بەهۆیەوە گیانی لەدەستدا " title=" رۆژی 7-10-2022 لە هەولێر، دوو بۆمب بە ئۆتۆمبێلی هاوکار جافدا تەقینەوە و بەهۆیەوە گیانی لەدەستدا " />
                                </a>
                            </div>
                            <div class="text-container selectionShareable">
                                <h4 class="article__aside-article-title">
                                    <a title="سزای لەسێدارەدان بۆ بەشێک لە تۆمەتبارانی دۆسیەی عەقید هاوکار جاف دەرچوو" href="https://www.rudaw.net/sorani/kurdistan/040620236">سزای لەسێدارەدان بۆ بەشێک لە تۆمەتبارانی دۆسیەی عەقید هاوکار جاف دەرچوو</a>
                                </h4>
                            </div>
                        </div>
                        <div class="article__aside-article article__aside-article selectionShareable">
                            <div class="img-container selectionShareable">
                                <a title="رێبەر ئەحمەد، وەزیری ناوخۆی حکومەتی هەرێمی کوردستان" href="https://www.rudaw.net/sorani/kurdistan/040620235" class="article__aside-article-link">
                                    <img src="https://www.rudaw.net/s3/rudaw.net/ContentFiles/736274Image1.jpg?mode=crop&amp;quality=70&amp;rand=1&amp;scale=both&amp;w=362&amp;h=226&amp;version=5305827" alt="رێبەر ئەحمەد، وەزیری ناوخۆی حکومەتی هەرێمی کوردستان" title="رێبەر ئەحمەد، وەزیری ناوخۆی حکومەتی هەرێمی کوردستان" />
                                </a>
                            </div>
                            <div class="text-container selectionShareable">
                                <h4 class="article__aside-article-title">
                                    <a title="وەزیری ناوخۆ: رێگەنادەین هیچ کەس و لایەنێک ئارامی و پێکەوەژیانی هەرێمی کوردستان تێکبدات" href="https://www.rudaw.net/sorani/kurdistan/040620235">وەزیری ناوخۆ: رێگەنادەین هیچ کەس و لایەنێک ئارامی و پێکەوەژیانی هەرێمی کوردستان تێکبدات</a>
                                </h4>
                            </div>
                        </div>
                        <div class="article__aside-article article__aside-article selectionShareable">
                            <div class="img-container selectionShareable">
                                <a title="وەزێڕ ئەحمەد، تەمەنی 21 ساڵ بوو" href="https://www.rudaw.net/sorani/kurdistan/040620234" class="article__aside-article-link">
                                    <img src="https://www.rudaw.net/s3/rudaw.net/ContentFiles/736258Image1.jpg?mode=crop&amp;quality=70&amp;rand=1&amp;scale=both&amp;w=362&amp;h=226&amp;version=5305625" alt="وەزێڕ ئەحمەد، تەمەنی 21 ساڵ بوو" title="وەزێڕ ئەحمەد، تەمەنی 21 ساڵ بوو" />
                                </a>
                            </div>
                            <div class="text-container selectionShareable">
                                <h4 class="article__aside-article-title">
                                    <a title="لە قەڵادزێ گەنجێکی 21 ساڵ بە چەقۆ دەکوژرێت" href="https://www.rudaw.net/sorani/kurdistan/040620234">لە قەڵادزێ گەنجێکی 21 ساڵ بە چەقۆ دەکوژرێت</a>
                                </h4>
                            </div>
                        </div>
                        <div class="article__aside-article article__aside-article selectionShareable">
                            <div class="img-container selectionShareable">
                                <a title="دروێنەی گەنم. وێنە: بڵند تاهیر - رووداو" href="https://www.rudaw.net/sorani/kurdistan/040620232" class="article__aside-article-link">
                                    <img src="https://www.rudaw.net/s3/rudaw.net/ContentFiles/736227Image1.jpg?mode=crop&amp;quality=70&amp;rand=1&amp;scale=both&amp;w=362&amp;h=226&amp;version=5305374" alt="دروێنەی گەنم. وێنە: بڵند تاهیر - رووداو" title="دروێنەی گەنم. وێنە: بڵند تاهیر - رووداو" />
                                </a>
                            </div>
                            <div class="text-container selectionShareable">
                                <h4 class="article__aside-article-title">
                                    <a title="وەرگرتنی گەنم لە گەرمیان راگیرا" href="https://www.rudaw.net/sorani/kurdistan/040620232">وەرگرتنی گەنم لە گەرمیان راگیرا</a>
                                </h4>
                            </div>
                        </div>



                    </div>
                </div>


            </aside >
        </>
    )
}

export default Aside