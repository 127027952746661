import React from 'react'

function Header() {
    return (
        <>

            <header class="">


                <div class="content">
                    <div class="header__search" id="DivSearch">
                        <div class="container">
                            <div class="header__search-form">
                                <form action="" class="">
                                    <div class="header__search-cont">

                                        <div id="pnlAutoSuggestSearch" class="innerSearch">
                                            <div class="headerSearch">
                                                <div class="floatRight">
                                                    <input id="txtAdvancedSearch" placeholder="گەڕان" resultslimit="5" lang="0" startfromnodeid="412599" autocomplete="off" type="text" />
                                                    <a title="" class="fa fa-search" href="#"></a>
                                                </div>
                                                <div class="clearBoth"></div>

                                            </div>
                                        </div>

                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                    <div class="header__top">
                        <div class="container">
                            <div class="header__top-cont">


                                <div class="headerTopFirst">

                                    <div class="header__top-lang">
                                        <ul class="header__top-langswitcher clear-style">
                                            <li id="langsorani" class="header__top-langswitcher-item">
                                                <a title="كوردى" href="/sorani" class="u u--white-thin fast">كوردى</a>
                                            </li>
                                            <li id="langkurmanci" class="header__top-langswitcher-item">
                                                <a title="Kurdî" href="/kurmanci" class="u u--white-thin fast">Kurdî</a>
                                            </li>
                                            <li id="langenglish" class="header__top-langswitcher-item">
                                                <a title="English" href="/english" class="u u--white-thin fast">English</a>
                                            </li>
                                            <li id="langturkish" class="header__top-langswitcher-item">
                                                <a title="Türkçe" href="/turkish" class="u u--white-thin fast">Türkçe</a>
                                            </li>
                                            <li id="langarabic" class="header__top-langswitcher-item">
                                                <a title="عربي" href="/arabic" class="u u--white-thin fast">عربي</a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="header__top-follow">
                                        <ul class="clear-style header__top-social">

                                            <li class="header__top-social-item">
                                                <a title="Facebook" href="https://www.facebook.com/Rudaw.net" target="_blank" class="header__top-social-link header__top-social-link--facebook">
                                                    Facebook
                                                </a>
                                            </li>
                                            <li class="header__top-social-item">
                                                <a title="Twitter" href="https://twitter.com/Rudawkurdish" target="_blank" class="header__top-social-link header__top-social-link--twitter">
                                                    Twitter
                                                </a>
                                            </li>
                                            <li class="header__top-social-item">
                                                <a title="Youtube" href="https://www.youtube.com/c/RudawMediaNetwork" target="_blank" class="header__top-social-link header__top-social-link--youtube">
                                                    Youtube
                                                </a>
                                            </li>
                                            <li class="header__top-social-item">
                                                <a title="Instagram" href="https://www.instagram.com/Rudaw/?hl=en" target="_blank" class="header__top-social-link header__top-social-link--instagram">
                                                    Instagram
                                                </a>
                                            </li>
                                            <li class="header__top-social-item">
                                                <a title="Snapchat" href="https://www.snapchat.com/add/Rudawmedia" class="header__top-social-link header__top-social-link--snapchat">
                                                    Snapchat
                                                </a>
                                            </li>
                                        </ul>

                                    </div>
                                    <div class="header__top-account">

                                    </div>
                                    <div class="clearBoth"></div>
                                </div>
                                <div class="headerTopBottom">
                                    <div class="header__main-logo">
                                        <a title="Rudaw" href="https://www.rudaw.net/sorani" class="">
                                            <img src="https://www.rudaw.net/images/logo.svg" width="90" alt="Rudaw" title="Rudaw" class="englishLogo" />
                                            <img src="/images/arabic-logo.png" alt="Rudaw" title="Rudaw" class="soraniLogo" />
                                        </a>
                                    </div>
                                    <div class="actionsShareMain">
                                        <div class="header__top-search">
                                            <form action="">
                                                <a title="Search" class="header__top-search-cta">
                                                    <img src="https://www.rudaw.net/images/ico-header-search.svg" width="16" alt="Search" title="Search" />

                                                </a>
                                                <span class="u u--white-thin fast">Search</span>
                                                <div class="header__top-search-cont">
                                                    <input type="text" class="header__top-search-input" />
                                                    <button class="header__top-search-button">Search</button>
                                                </div>
                                            </form>
                                        </div>
                                        <div class="header__top-action">
                                            <a title="Radio" href="https://www.rudaw.net/sorani/onair/radio/live" class="header__top-action-radio">
                                                <span class="u u--white-thin fast">Radio</span>
                                            </a>
                                            <a title="TV" href="https://www.rudaw.net/sorani/onair/tv/live" class="header__top-action-tv">
                                                <span class="u u--white-thin fast">TV</span>
                                            </a>
                                            <a title="TV Audio" href="https://www.rudaw.net/sorani/onair/tv/live?audio=1" class="header__top-action-tv">
                                                <span class="u u--white-thin fast">TV Audio</span>
                                            </a>

                                        </div>
                                    </div>
                                    <div class="clearBoth"></div>


                                </div>



                            </div>
                        </div>
                    </div>
                    <div class="header__main">
                        <div class="stickyHeader">
                            <div class="container">
                                <div class="header__main-inner">
                                    <div class="header__main-logo">
                                        <a title="Rudaw" href="https://www.rudaw.net/sorani" class="">
                                            <img src="/images/logo.svg" width="90" alt="Rudaw" title="Rudaw" class="englishLogo" />
                                            <img src="/images/arabic-logo.png" alt="Rudaw" class="soraniLogo" title="Rudaw" />
                                        </a>
                                    </div>



                                    <a title="Menu" href="javascript:;" class="mobileMenuToggle"></a>
                                    <ul class="header__main-menu clear-style">
                                        <li class="header__main-menu-item l ">


                                            <a title="کوردستان" href="https://www.rudaw.net/sorani/kurdistan" class="header__main-menu-link u u--white-thick fast ">
                                                کوردستان
                                            </a>
                                        </li>
                                        <li class="header__main-menu-item l ">


                                            <a title="رۆژهەڵاتی&amp;nbsp;نێوەڕاست" href="https://www.rudaw.net/sorani/middleeast" class="header__main-menu-link u u--white-thick fast ">
                                                رۆژهەڵاتی&nbsp;نێوەڕاست
                                            </a>
                                        </li>
                                        <li class="header__main-menu-item l ">


                                            <a title="جیهان" href="https://www.rudaw.net/sorani/world" class="header__main-menu-link u u--white-thick fast ">
                                                جیهان
                                            </a>
                                        </li>
                                        <li class="header__main-menu-item l ">


                                            <a title="ئابووری" href="https://www.rudaw.net/sorani/business" class="header__main-menu-link u u--white-thick fast ">
                                                ئابووری
                                            </a>
                                        </li>
                                        <li class="header__main-menu-item l ">


                                            <a title="هەڤپەیڤین" href="https://www.rudaw.net/sorani/interview" class="header__main-menu-link u u--white-thick fast ">
                                                هەڤپەیڤین
                                            </a>
                                        </li>
                                        <li class="header__main-menu-item l ">


                                            <a title="بیروڕا" href="https://www.rudaw.net/sorani/opinion" class="header__main-menu-link u u--white-thick fast ">
                                                بیروڕا
                                            </a>
                                        </li>
                                        <li class="header__main-menu-item l ">


                                            <a title="کولتوور&amp;nbsp;" href="https://www.rudaw.net/sorani/culture" class="header__main-menu-link u u--white-thick fast ">
                                                کولتوور&nbsp;
                                            </a>
                                        </li>
                                        <li class="header__main-menu-item l ">


                                            <a title="تەندروستی" href="https://www.rudaw.net/sorani/health" class="header__main-menu-link u u--white-thick fast ">
                                                تەندروستی
                                            </a>
                                        </li>
                                        <li class="header__main-menu-item l ">


                                            <a title="وەرزش" href="https://www.rudaw.net/sorani/sports" class="header__main-menu-link u u--white-thick fast ">
                                                وەرزش
                                            </a>
                                        </li>
                                        <li class="header__main-menu-item l ">


                                            <a title="مەڵتیمیدیا" href="https://www.rudaw.net/sorani/multimedia" class="header__main-menu-link u u--white-thick fast ">
                                                مەڵتیمیدیا
                                            </a>
                                        </li>
                                        <li class="header__main-menu-item l ">


                                            <a title="بەرنامەکان" href="https://www.rudaw.net//sorani/onair/tv" class="header__main-menu-link u u--white-thick fast ">
                                                بەرنامەکان
                                            </a>
                                        </li>
                                        <li class="header__main-menu-item l ">


                                            <a title="دۆکیۆمێنتاری" href="https://www.rudaw.net/sorani/documentary" class="header__main-menu-link u u--white-thick fast ">
                                                دۆکیۆمێنتاری
                                            </a>
                                        </li>
                                    </ul>
                                    <div>



                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>




                </div>
            </header>
            <div class="headerHeight"></div>



        </>
    )
}

export default Header